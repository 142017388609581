import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import css from 'app/styles/editors/media-editor.css';
import * as types from 'app/types';
import {
	MediaImageThumbnail,
	MediaVideoThumbnail,
	MediaAlternativeThumbnail,
	MediaLandscapeScreenshot,
	MediaPortraitScreenshot,
	MediaMisc,
	MediaOriginalsBackground,
	MediaOriginalsMain,
	MediaOriginalsEvent,
	MediaOriginalsLogo,
	MediaOriginalsPropsLeft,
	MediaOriginalsPropsRight,
	MediaOriginalsCarouselBackground,
} from 'app/components/editors/util/mediaTypes';
import { selectMediasByID } from 'app/selectors/medias';
import MediaDragDropList from 'app/components/editors/MediaDragDropList';

class MediaEditor extends React.PureComponent {
	static propTypes = {
		mediaCollectionID: PT.string.isRequired,
		disableThumbnailEdit: PT.bool,
		medias: PT.arrayOf(types.media),
	};

	static defaultProps = {
		medias: [],
		disableThumbnailEdit: false,
	};

	render() {
		const { mediaCollectionID, medias, disableThumbnailEdit } = this.props;

		return (
			<div className={css.editor}>
				{!disableThumbnailEdit && <div className={css.column}><MediaDragDropList mediaCollectionID={mediaCollectionID} items={medias.filter(m => m.type === MediaImageThumbnail)} type={MediaImageThumbnail} /></div>}
				<div className={css.column}><MediaDragDropList mediaCollectionID={mediaCollectionID} items={medias.filter(m => m.type === MediaVideoThumbnail)} type={MediaVideoThumbnail} /></div>
				<div className={css.column}><MediaDragDropList mediaCollectionID={mediaCollectionID} items={medias.filter(m => m.type === MediaAlternativeThumbnail)} type={MediaAlternativeThumbnail} /></div>
				<div className={css.column}><MediaDragDropList mediaCollectionID={mediaCollectionID} items={medias.filter(m => m.type === MediaLandscapeScreenshot)} type={MediaLandscapeScreenshot} /></div>
				<div className={css.column}><MediaDragDropList mediaCollectionID={mediaCollectionID} items={medias.filter(m => m.type === MediaPortraitScreenshot)} type={MediaPortraitScreenshot} /></div>
				<div className={css.column}><MediaDragDropList mediaCollectionID={mediaCollectionID} items={medias.filter(m => m.type === MediaMisc)} type={MediaMisc} /></div>

				<h1 className={css.title}>Originals</h1>

				<div className={css.column}><MediaDragDropList mediaCollectionID={mediaCollectionID} items={medias.filter(m => m.type === MediaOriginalsBackground)} type={MediaOriginalsBackground} /></div>
				<div className={css.column}><MediaDragDropList mediaCollectionID={mediaCollectionID} items={medias.filter(m => m.type === MediaOriginalsMain)} type={MediaOriginalsMain} /></div>
				<div className={css.column}><MediaDragDropList mediaCollectionID={mediaCollectionID} items={medias.filter(m => m.type === MediaOriginalsEvent)} type={MediaOriginalsEvent} /></div>
				<div className={css.column}><MediaDragDropList mediaCollectionID={mediaCollectionID} items={medias.filter(m => m.type === MediaOriginalsLogo)} type={MediaOriginalsLogo} /></div>
				<div className={css.column}><MediaDragDropList mediaCollectionID={mediaCollectionID} items={medias.filter(m => m.type === MediaOriginalsPropsLeft)} type={MediaOriginalsPropsLeft} /></div>
				<div className={css.column}><MediaDragDropList mediaCollectionID={mediaCollectionID} items={medias.filter(m => m.type === MediaOriginalsPropsRight)} type={MediaOriginalsPropsRight} /></div>
				<div className={css.column}><MediaDragDropList mediaCollectionID={mediaCollectionID} items={medias.filter(m => m.type === MediaOriginalsCarouselBackground)} type={MediaOriginalsCarouselBackground} /></div>
			</div>
		);
	}
}

export default connect((state, { mediaCollectionID }) => ({
	mediaCollectionID,
	medias: selectMediasByID(state, mediaCollectionID),
}))(MediaEditor);
