import { SERVICE_ENV } from 'app/utils/api';

export const MediaImageThumbnail = 'image_thumbnail';
export const MediaVideoThumbnail = 'video_thumbnail';
export const MediaAlternativeThumbnail = 'alternative_thumbnail';
export const MediaLandscapeScreenshot = 'landscape_screenshot';
export const MediaPortraitScreenshot = 'portrait_screenshot';
export const MediaMisc = 'miscellaneous';
export const MediaOriginalsBackground = 'originals_background';
export const MediaOriginalsMain = 'originals_main';
export const MediaOriginalsEvent = 'originals_event';
export const MediaOriginalsPropsLeft = 'originals_props_left';
export const MediaOriginalsPropsRight = 'originals_props_right';
export const MediaOriginalsLogo = 'originals_logo';
export const MediaOriginalsCarouselBackground = 'originals_carousel_background';

export const MediaStatusUploading = 'Uploading';
export const MediaStatusTranscoding = 'Transcoding';
export const MediaStatusComplete = 'Complete';

export const OriginalsMediaTypes = [
	MediaOriginalsBackground,
	MediaOriginalsMain,
	MediaOriginalsEvent,
	MediaOriginalsLogo,
	MediaOriginalsPropsLeft,
	MediaOriginalsPropsRight,
	MediaOriginalsCarouselBackground,
];

export const CDNLocation = media => {
	switch (media.type) {
	case MediaImageThumbnail:
	case MediaAlternativeThumbnail:
		return `https://img.poki-cdn.com/cdn-cgi/image/f=auto/${media.source}`;
	case MediaVideoThumbnail:
		return `https://v${['acceptance', 'local'].includes(SERVICE_ENV) ? '-acceptance' : ''}.poki-cdn.com/${media.animated_thumbnail_base}`;
	case MediaLandscapeScreenshot:
		return `https://img.poki-cdn.com/${media.source}`;
	case MediaPortraitScreenshot:
		return `https://img.poki-cdn.com/${media.source}`;
	case MediaMisc:
		return `https://m.poki-cdn.com/${media.source}`;
	case MediaOriginalsBackground:
	case MediaOriginalsMain:
	case MediaOriginalsEvent:
	case MediaOriginalsLogo:
	case MediaOriginalsPropsLeft:
	case MediaOriginalsPropsRight:
	case MediaOriginalsCarouselBackground:
		return `https://img.poki-cdn.com/${media.source}`;
	}

	return '';
};
