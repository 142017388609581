import React, { createRef } from 'react';
import PT from 'prop-types';
import Form from 'react-jsonschema-form';

import { gameDetailsSchema, uiGameDetailsSchema } from 'app/forms/game';
import { releaseStatusSchema, uiReleaseStatusSchema } from 'app/forms/releaseStatus';
import { translationsSchema, uiTranslationSchema } from 'app/forms/translation';
import { metadataSchema, uiMetadataSchema } from 'app/forms/metadata';

import css from 'app/styles/forms/form.css';
import Button from 'app/components/ui/Button';
import * as types from 'app/types';

import deepEqual from 'fast-deep-equal';
import { autoTranslateForm } from 'app/utils/autoTranslateForm';

export default class GameForm extends React.Component {
	static propTypes = {
		categories: PT.arrayOf(types.game).isRequired,
		sites: PT.objectOf(types.site).isRequired,
		series: PT.arrayOf(types.series).isRequired,
		onSubmit: PT.func.isRequired,
		details: types.game,
	};

	static defaultProps = {
		details: undefined,
	};

	static steps = {
		1: {
			schema: gameDetailsSchema,
			ui: uiGameDetailsSchema,
			title: 'Details',
		},
		2: {
			schema: releaseStatusSchema,
			ui: uiReleaseStatusSchema,
			title: 'Release Status',
		},
		3: {
			schema: () => translationsSchema({ titlesEditAllowed: true }),
			ui: uiTranslationSchema({ titlesEditAllowed: true }),
			title: 'Translations',
		},
		4: {
			schema: metadataSchema,
			ui: uiMetadataSchema,
			title: 'Metadata',
		},
		5: {
			schema: () => ({}),
			ui: {},
			title: 'Save',
		},
	};

	constructor(props) {
		super(props);
		this.state = { step: 1, formData: false, formShadowData: JSON.parse(JSON.stringify(props.details)) };
		this.form = createRef();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { details } = nextProps;

		this.setState({
			formData: {
				...details,
			},
			formShadowData: JSON.parse(JSON.stringify(details)),
		});
	}

	shouldComponentUpdate(nextProps, nextState) {
		// Don't re-render if formShadowData changes, but re-render on any other change
		const stateWithoutShadowFormData = { ...this.state };
		delete stateWithoutShadowFormData.formShadowData;

		const eqB = { ...nextState };
		delete eqB.formShadowData;

		return !deepEqual(nextProps, this.props) || !deepEqual(eqB, stateWithoutShadowFormData);
	}

	handleAutoTranslate = autoTranslateForm.bind(this);

	onSubmit = data => {
		try {
			JSON.parse(data.formData.config);
		} catch (e) {
			// eslint-disable-next-line no-alert
			alert('Invalid JSON in config field');
			return false;
		}

		if (data.formData.release_status === 'not-released' && data.formData.release_status_overrides.length > 0) {
			// eslint-disable-next-line no-alert
			alert('You cannot have release status overrides for a game that is not released.');
			return;
		}

		if (this.state.step < Object.keys(GameForm.steps).length) {
			if (data.formData.desktop.orientation !== 0) {
				data.formData.desktop.orientation = 0; // force orientation to Both on desktop
			}
			this.setState({
				step: this.state.step + 1,
				formData: {
					...this.state.formData,
					...data.formData,
				},
			}, () => {
				if (this.state.step === Object.keys(GameForm.steps).length) {
					this.props.onSubmit(this.state.formData);
				}
			});
		}
	};

	onChange = data => {
		const { formData } = data;
		const { formData: previousFormData } = this.state;

		let formShadowDataRef = formData;

		if (formData.platform === 'Both' && (!previousFormData || previousFormData.platform === 'Desktop Only')) {
			const newFormData = {
				...formData,
				mobile: {
					...formData.desktop, // Prefill the mobile version with the desktop data.
				},
			};

			// we need to make sure we update the shadow data as well
			formShadowDataRef = newFormData;

			this.setState({
				formData: newFormData,
			});
		}
		this.setState({ formShadowData: JSON.parse(JSON.stringify(formShadowDataRef)) });
	};

	handleBack = () => {
		this.setState({
			step: this.state.step - 1,
		});
	};

	render() {
		const { categories, sites, series } = this.props;
		if (categories.length === 0) {
			return <div>Loading...</div>;
		}

		const { step, formData } = this.state;

		const showForm = step < Object.keys(GameForm.steps).length;
		const schema = GameForm.steps[step].schema({ sites, categories, series });

		const isTranslations = GameForm.steps[step].title === 'Translations';

		return (
			<div>
				{isTranslations && (
					<Button primary onClick={this.handleAutoTranslate}>Auto translate</Button>
				)}
				<Form
					schema={schema}
					formData={formData}
					uiSchema={GameForm.steps[step].ui}
					noValidate
					onSubmit={this.onSubmit}
					onChange={this.onChange}
					ref={this.form}
				>
					{!showForm && (
						<div>Processing your update!! Hang in there!</div>
					)}
					<div>
						{GameForm.steps[step - 1] && (
							<Button primary={false} size="normal" onClick={this.handleBack}>
								Back:
								{GameForm.steps[step - 1].title}
							</Button>
						)}
						<Button submit size="normal" className={css.right}>
							Next:
							{GameForm.steps[step + 1] ? GameForm.steps[step + 1].title : 'Save'}
						</Button>
					</div>
				</Form>
			</div>
		);
	}
}
